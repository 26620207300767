<template>
  <section class="occupancyRoomsGeneral">
    <div class="occupancyRoomsGeneral__contentDescription">
      <p class="occupancyRoomsGeneral__description">Promedio de horas por todas las ciudades</p>
    </div>
    <div class="occupancyRoomsGeneral__contentData">
      <div class="occupancyRoomsGeneral__loadingLine skeletonS" v-if="isLoading"></div>
      <line-chart
        :min="0"
        :max="100"
        suffix="%"
        :height="'500px'"
        :download="true"
        :discrete="true"
        :data="hourAndDay"
        :library="chartOptions"
        v-else-if="hourAndDay.length"
      ></line-chart>
      <span v-else>No se han registrado horas de conexion en este filtro</span>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      chartOptions: {
        responsive: true,
        colors: ["#165BAA", "#A155B9", "#F765A3", "#FFCD56", "#4BC0C0", "#FF6B00", "#CFD1D4"],
      },
    };
  },
  computed: {
    ...mapGetters("Occupation", ["hourAndDay", "loadingIn"]),
    isLoading() {
      return this.loadingIn("hourAndDay");
    },
  },
  beforeDestroy() {
    this.$store.commit(`Occupation/changeLoadingIn`, { name: "hourAndDay", value: true });
  },
};
</script>

<style lang="scss">
.occupancyRoomsGeneral {
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  &__contentDescription {
    padding: 20px;
    border-bottom: 2px solid #ececec;
  }
  &__description {
    font-size: 17px;
    font-weight: 600;
  }
  &__contentData {
    @include Flex(row);
    gap: 10px;
    padding: 15px 20px 20px 20px;
  }
  &__loadingText {
    height: 37px;
    width: 175px;
    border-radius: 12px;
  }
  &__loadingLine {
    height: 500px;
    width: 100%;
    border-radius: 12px;
  }
}
</style>
